import Button from 'components/Button'
import Link from 'next/link'
import Meta from 'components/Meta'

export default function Custom404() {
  const email = 'feedback@txnlab.dev'
  const emailSubject = '404'
  const emailBody = 'I got a 404 trying to access a page'

  return (
    <>
      <Meta title="Page not found" />
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 dark:bg-gray-900">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-brand-600 sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6 dark:sm:border-gray-700">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl dark:text-gray-200">
                  Page not found
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  Go back home
                </Link>
                <a href={`mailto:${email}?subject=${emailSubject}&body=${emailBody}`}>
                  <Button>Contact support</Button>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
